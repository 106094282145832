import React from "react"
import { graphql } from 'gatsby'

import Layout from "../components/layout"
import Seo from "../components/seo"
import FeaturedProjectBlock from "../components/featured-project-block"

const ProjectTypeTemplate = ({data}) => (
  <Layout>
    <Seo title={data.wpProjectType.name} />
    <h1 className="mb-2 text-scplightgrey">{data.wpProjectType.name} </h1>
    <FeaturedProjectBlock featuredProjects={data.allWpProject.edges}/>
  </Layout>
)

export const query = graphql`
  query ProjectTypeQuery($id : String, $wordpress_id : Int) {
    wpProjectType(id: {eq: $id}) {
      id
      name
    }      
    allWpProject(filter: {projectTypes: {nodes: {elemMatch: {databaseId: {eq: $wordpress_id}}}}}, sort: {fields: date, order: DESC}) {
      ...FeaturedProjectsFragment
    }
  }
`

export default ProjectTypeTemplate